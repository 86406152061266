import React, { useEffect } from 'react';
import IndexPage from './index';
import { setCookie } from '../services/cookies';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';

const Facebook20: React.FC = () => {
  useEffect(() => {
    setCookie(COOKIES.banner, 'facebook', COOKIE_EXPIRES_IN.fourteenDays);
  }, []);

  return <IndexPage />;
};

export default Facebook20;
